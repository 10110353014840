import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../layouts/layout"
import Breadcrumbs from "~c/partials/breadcrumbs/breadcrumbs"

import ArticleCard from "~c/ui/cards/article-card/article-card"

import "./blog.scss"

interface Props {
  location: {
    hash: string
    host: string
    hostname: string
    href: string
    key: string
    origin: string
    pathname: string
    port: string
    protocol: string
    search: string
    state: string
  }
}

interface Node {
  frontmatter: {
    title: string
    tags: []
    slug: string
    image: any
    shortscription: string
    author: string
    date_published: Date
    date_modified: Date
    author_avatar: any
  }
  id: string
}

export default ({ location }: Props) => {
  const { allMarkdownRemark } = useStaticQuery(query)

  return (
    <Layout
      meta={{
        title: `Blog`,
        description: `Blog articles focused on nowadays technical information coverage in Web development environment and not only!`,
        canonical: location.pathname,
      }}
    >
      <div className="view view-blog">
        <Breadcrumbs location={location} parentClassName="text-center mb-16" />
        <h1 className="visually-hidden"> Blog </h1>
        <section className="view-blog__cards">
          {allMarkdownRemark.nodes.map((node: Node) => {
            const { frontmatter } = node

            return <ArticleCard articleData={node} key={frontmatter.title} />
          })}
        </section>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/(articles)/" } }
      limit: 8
      sort: { fields: frontmatter___date_modified, order: DESC }
    ) {
      nodes {
        frontmatter {
          title
          tags
          slug
          shortscription
          author
          date(formatString: "MMMM DD, YYYY")
          image {
            childImageSharp {
              gatsbyImageData(formats: [AUTO, WEBP])
            }
          }
          author_avatar {
            childImageSharp {
              gatsbyImageData(formats: [AUTO, WEBP])
            }
          }
        }
        id
      }
    }
  }
`
