import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

import "./article-card.scss"

interface Props {
  articleData: {
    frontmatter: {
      title: string
      tags: []
      slug: string
      image: any
      shortscription: string
      author: string
      author_avatar: any
      date_published: Date
      date_modified: Date
    }
    id: string
  }
}

export default ({ articleData }: Props) => {
  const {
    title,
    tags,
    slug,
    image,
    shortscription,
    author,
    author_avatar,
    date_modified,
  } = articleData.frontmatter

  const imageData = getImage(image)
  const avatarData = getImage(author_avatar)

  return (
    <article
      className="article-card"
      itemScope
      itemType="http://schema.org/Article"
    >
      <div className="article-card__image">
        {imageData ? (
          <GatsbyImage image={imageData} alt={title} itemProp="image" />
        ) : null}
      </div>
      <div className="article-card__content">
        <div className="article-card__author mb-32">
          <div className="article-card__author-image-wrapper mb-24">
            <div className="article-card__author-image">
              {avatarData ? (
                <GatsbyImage image={avatarData} alt={title} />
              ) : null}
            </div>
          </div>
          <div className="article-card__author-name">
            <strong itemProp="author">{author}</strong>
          </div>
        </div>
        <h3 className="article-card__title mb-24" itemProp="name" title={title}>
          <Link
            to={slug}
            itemProp="url"
            dangerouslySetInnerHTML={{ __html: title }}
          ></Link>
        </h3>
        <div className="article-card__text mb-24" itemProp="description">
          <Link to={slug} itemProp="url" dangerouslySetInnerHTML={{ __html: shortscription }}></Link>
        </div>
        <div className="article-card__meta">
          <div className="article-card__tags">
            {tags.map(tag => {
              return (
                <div
                  className="article-card__tag"
                  itemProp="keywords"
                  title={tag}
                  key={tag}
                >
                  {tag}
                </div>
              )
            })}
          </div>
          <div className="article-card__date" itemProp="datePublished">
            {date_modified}
          </div>
        </div>
      </div>
    </article>
  )
}
